@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.member-contact-info {
  background-color: light.$background-secondary-default;
  border-radius: corner-radius.$s;

  &__top {
    padding: spacing.$m;
    border-bottom: 1px solid light.$ge-divider-default;
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
  }

  &__top-inner {
    display: flex;
    align-items: center;
    gap: spacing.$s;
  }

  &__image-wrapper {
    border-radius: corner-radius.$m;
    width: 80px;
    height: 80px;
    overflow: hidden;
    flex-shrink: 0;
    color: light.$on-surface-primary-alternate;
  }

  &__basic-info {
    display: flex;
    flex-direction: column;
  }

  &__basic-info-sub {
    color: light.$on-surface-primary-alternate;
  }
}
